import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"src/app/(auth)/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/react-bootstrap/esm/Col.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/react-bootstrap/esm/Row.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/providers/GlobalAnimationProvider.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/workspace/src/styles/_app.scss");
